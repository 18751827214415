import React from 'react'
import { H1, H2 } from '@blueprintjs/core'

import Header from 'components/Header'
import Footer from 'components/Footer'

import hero from 'images/hero.png'
import appIcon from 'images/appIcon.png'
import iosBadge from 'images/iosBadge.png'
import androidBadge from 'images/androidBadge.png'

import 'styles/About.css'

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header />

        <content className="About">
          <H1>
            UCLA Mobile
          </H1>

          <img src={hero} alt="Mobile app screenshots" className="hero" />

          <H2 className="available">
            UCLA Mobile Available for iOS and Android
          </H2>

          <div className="description">
            <img src={appIcon} alt="" className="app-icon" />

            The UCLA mobile App provides a portal of UCLA's approved mobile
            apps, presenting a collection of UCLA's most essential apps
            while also allowing individuals to personalize their own
            dashboard with additional UCLA apps that they want to include in
            their UCLA mobile app environment.
            UCLA Mobile also features the ability for users to choose their
            dashboard preferences so that content is specific for their role
            at the university.
            Users can sign up for message notifications, view resources, see
            news, and more.
            <br />
            <br />

            The UCLA Mobile App has 6 Dashboards:
          </div>

          <ul className="description">
            <li>
              Students
            </li>
            <li>
              Faculty
            </li>
            <li>
              Staff
            </li>
            <li>
              Alumni
            </li>
            <li>
              Prospective Students
            </li>
            <li>
              Friends of UCLA
            </li>
          </ul>

          <div className="badges">
            <a href="https://apple.co/3oLUNwk">
              <img src={iosBadge} alt="Download on the App Store" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=org.uclaMobile.ohmage">
              <img src={androidBadge} alt="Get it on Google Play" />
            </a>
          </div>

          <Footer />
        </content>
      </React.Fragment>
    )
  }
}

export default About
