const Colors = {
  blue: '#2774ae',
  darkBlue: '#005587',
  darkGray: '#222222',
  gray: '#757575',
  green: '#207b6c',
  lightGray: '#eeeeee',
  red: '#ff0000',
  yellow: '#ffd100'
}

export default Colors
