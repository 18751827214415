import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import About from 'pages/About'

import 'normalize.css/normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path="/" component={About} />
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default App
