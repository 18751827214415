import React from 'react'
import { Navbar, Alignment } from '@blueprintjs/core'

import StyledButton from 'components/StyledButton'
import logo from 'images/logo.svg'
import Gradient from 'styles/gradient'

import 'styles/header.css'

class Header extends React.Component {
  render() {
    return (
      <header>
        <Navbar
          className="bp3-dark"
          fixedToTop
          style={{
            background: Gradient,
            height: '70px',
            paddingTop: '10px',
            paddingBottom: '10px',
            boxShadow: 'none'
          }}
        >
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
              <img
                src={logo}
                alt="UCLA"
              />
            </Navbar.Heading>
          </Navbar.Group>

          <Navbar.Group align={Alignment.RIGHT}>
            <StyledButton
              id="app-store-button"
              colorClass="yellow"
              href="https://apps.ucla.edu"
            >
              UCLA App Store
            </StyledButton>
          </Navbar.Group>
        </Navbar>
      </header>
    )
  }
}

export default Header
