import React from 'react'
import { Link } from 'react-router-dom'

class Footer extends React.Component {
  render() {
    const ppLink = (
      <Link to="#">
        Privacy Policy
      </Link>
    )

    return (
      <footer className={this.props.light ? 'light' : null}>
        &copy; 2023 UC Regents. All Rights Reseved. | {ppLink}
      </footer>
    )
  }
}

export default Footer
